import { qq, q } from "ts/library/dom";

qq('.demos article').forEach(articleElement => {
    const audioElement = q('audio', articleElement) as HTMLAudioElement;
    const playElement = q('.play', articleElement);
    if (audioElement && playElement) {
        playElement.addEventListener('click', () => {
            audioElement[playElement.classList.contains('playing') ? 'pause' : 'play']();
            playElement.classList.toggle('playing');
            demosPauseAll(playElement);
        });
        audioElement.onended = () => playElement.classList.remove('playing');
    }
});

function demosPauseAll(currentPlayElement?: HTMLElement) {
    (qq('.demos article') as HTMLAudioElement[]).forEach(articleElement => {
        const audioElement = q('audio', articleElement) as HTMLAudioElement;
        const playElement = q('.play', articleElement);
        if (audioElement && playElement && playElement !== currentPlayElement) {
            audioElement.pause();
            playElement.classList.remove('playing');
        }
    });
}